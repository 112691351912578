import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import uuid from "uuid/v1";

import * as signalR from "@microsoft/signalr";
import { $msal } from "~/utils/msal";

import talentProfileService, {
  getLinkedInTalentInfo,
  removeLinkedInTalent,
  updateLinkedInData,
} from "~/services/talentProfileService";
import { TalentExpectations } from "~/models/TalentExpectations";
import { UpdateExperienceRequest } from "~/models/UpdateExperienceRequest";
import {
  EmploymentSaved,
  EducationSaved,
  RoleWithTitles,
  EducationToAdd,
  EmploymentToAdd,
  TalentMessage,
  JobbOfferVisualForView,
  CompanyProfileDto,
  TalentRoleWithTasks,
  CompletionListItem,
  CompletionListType,
  BusinessAreasType,
  SignalRForTalent,
  PublicJobOfferDto,
  TalentUserObject,
  GetTalentPoolsForTalentDtoWithDates,
  ThingToLoad,
  PublishedCompoanyProfileDto,
  JobOfferTalentInfo,
  linkedInCompany,
} from "~/models/types";

import { TalentTasksAndSkills } from "~/models/TalentTasksAndSkills";

import { TalentExperience } from "~/models/TalentExperience";
import { WorkFromHome } from "~/models/WorkFromHome";
import { ListObject } from "~/models/ListObject";
import {
  profileLookupsStore,
  talentProfileStore,
  notificationsStore,
} from "~/store";
import { TalentProfileDto } from "~/models/TalentProfileDto";
import TalentHelpers from "~/helpers/talentHelpers";
import SortHelper from "~/helpers/sortHelper";
import SignalRHelper from "~/helpers/SignalRHelper";
import { $i18n } from "~/utils/i18n";
import TokenHelpers from "~/helpers/tokenHelpers";
import { authStore } from "~/utils/store-accessor";
import JobOfferPublicService from "~/services/jobOfferPublicService";
import FilterHelpers from "~/helpers/filterHelpers";
import TalentPoolService from "~/services/talentPoolService";
import CompanyProfileHelper from "~/helpers/CompanyProfileHelper";
import { roleIdForOtherRole } from "~/helpers/TitleHelper";
import lookupDataService from "~/services/lookupDataService";
import { LinkedInTalentInfo } from "~/models/linkedInTalentInfo";
import { getThingOrNull } from "~/helpers/CommonHelperFunctions";
import { getProfileIdFromUrl } from "~/helpers/linkedInTalentHelper";
import {
  dontNeedEmployments,
  dontNeedTasks,
} from "~/helpers/talentProfileHelper";

const defaultTasksAndSkills: TalentTasksAndSkills = {
  hiddenRoleIds: [],
  skills: [],
  roles: [],
};

const defaultExperience: TalentExperience = {
  branchIds: [],
  education: [],
  employments: [],
  languageIds: [],
  responsibilities: [],
  hasNoEducation: false,
  numberOfWorkYearsStartDate: null,
  noEmploymentsReason: null,
};

interface EmploymentWithAllEmployments extends EmploymentSaved {
  allEmps: EmploymentSaved[];
}

const defaultExpectations: TalentExpectations = {
  requireNoTrips: false,
  requireNoConsultants: false,
  requireNoStartups: false,
  requireNoPublicSectors: false,
  requireStableSalary: null,
  requireWorkFromHome: WorkFromHome.No,
  requiredCities: [],
  responsibilityMusts: [],
  salary: null,
  salaryCurrency: "SEK",
  roleIds: [],
  rejectedCompanyIds: [],
};

const defaultProfile: TalentProfileDto = {
  firstName: null,
  isDone: false,
  lastName: null,
  taskLang: null,
  understandRespondImportance: false,
  expectations: defaultExpectations,
  experience: defaultExperience,
  lang: "sv",
  tasksAndSkills: defaultTasksAndSkills,
  numberOfAutoDeclines: 0,
  pausedTo: null,
};

@Module({
  name: "talentProfile",
  stateFactory: true,
  namespaced: true,
})
export default class TalentProfile extends VuexModule {
  profile: TalentProfileDto = defaultProfile;

  talentProfileIsLoaded: string | null = null;

  jobOfferVisaul: JobbOfferVisualForView | null = null;

  companyProfile: CompanyProfileDto | null = null;

  jobOffersInternalInternal: JobbOfferVisualForView[] = [];
  otherPublicJobOffers: JobbOfferVisualForView[] = [];

  @Mutation
  SET_OTHER_PUBLIC_JOB_OFFERS(v: JobbOfferVisualForView[]) {
    this.otherPublicJobOffers = v;
  }

  hasAskedAboutContactInfo: boolean = false;

  talentUserObject: TalentUserObject | null = null;

  @Mutation
  SET_TALENT_USER_OBJECT(v: TalentUserObject | null) {
    this.talentUserObject = v;
  }

  @Mutation
  SET_HAS_ASKED_ABOUT_CONTACT_INFO(v: boolean) {
    this.hasAskedAboutContactInfo = v;
  }

  get jobOffers(): JobbOfferVisualForView[] {
    return [...this.jobOffersInternal]
      .filter(x => !x.isRemovedBeforeAnswered)
      .sort((b, a) => {
        const getDate: (x: JobbOfferVisualForView) => Date = x => {
          const maxDate = new Date(8640000000000000);
          return (
            (FilterHelpers.hasDeclined(x) ? x.declined : null) ||
            x.removed ||
            x.accepted ||
            x.invited ||
            maxDate
          );
        };
        const aDate = getDate(a);
        const bDate = getDate(b);

        return aDate > bDate ? 1 : bDate > aDate ? -1 : 0;
      });
  }

  linkedInTalentInfo: ThingToLoad<LinkedInTalentInfo | null> = {
    type: "notFetched",
  };

  @Mutation
  SET_LINKED_IN_TALENT_INFO(v: ThingToLoad<LinkedInTalentInfo | null>) {
    this.linkedInTalentInfo = v;
  }

  @Action
  async loadLinkedInTalentInfo() {
    const ttl = TokenHelpers.getThingToLoadOrExit(
      this.linkedInTalentInfo,
      false
    );

    if (ttl.type === "exit") {
      return;
    }
    talentProfileStore.SET_LINKED_IN_TALENT_INFO(ttl);

    const token = await TokenHelpers.getToken();
    const result = await getLinkedInTalentInfo(token);
    talentProfileStore.SET_LINKED_IN_TALENT_INFO({
      type: "loaded",
      value: result,
    });
  }

  @Action
  async updateLinkedInUrl(v: {
    linkedInUrl: string;
  }): Promise<LinkedInTalentInfo | null> {
    const linkedInData = getThingOrNull(this.linkedInTalentInfo);
    if (
      linkedInData &&
      linkedInData.profileId === getProfileIdFromUrl(v.linkedInUrl)
    ) {
      return linkedInData;
    }

    const accessToken = await TokenHelpers.getToken();
    const result = await updateLinkedInData({
      accessToken,
      linkedInUrl: v.linkedInUrl,
    });
    talentProfileStore.SET_LINKED_IN_TALENT_INFO({
      type: "loaded",
      value: result,
    });

    return result;
  }

  @Action
  async removeLinkedIn(): Promise<void> {
    const accessToken = await TokenHelpers.getToken();
    await removeLinkedInTalent({
      accessToken,
    });
    talentProfileStore.SET_LINKED_IN_TALENT_INFO({
      type: "loaded",
      value: null,
    });
  }

  @Mutation
  SET_TALENT_PROFILE_LOADED(loaded: string | null) {
    this.talentProfileIsLoaded = loaded;
  }

  @Mutation
  SET_JOBB_OFFER_VISUAL_BY_ID(jobOfferVisaul: JobbOfferVisualForView) {
    this.jobOfferVisaul = jobOfferVisaul;
  }

  @Mutation
  SET_COMPANY_PROFILE_BY_ID(profile: CompanyProfileDto) {
    this.companyProfile = profile;
  }

  @Mutation
  SET_TALENT_TASKS_AND_SKILLS(v: TalentTasksAndSkills) {
    this.profile.tasksAndSkills = v;
  }

  @Mutation
  SET_ROLES_WITH_TASKS(v: TalentRoleWithTasks[]) {
    const tasksAndSkills = this.profile.tasksAndSkills || defaultTasksAndSkills;

    this.profile.tasksAndSkills = { ...tasksAndSkills, roles: v };
  }

  @Mutation
  SET_SKILLS(skills: ListObject[]) {
    const tasksAndSkills = this.profile.tasksAndSkills || defaultTasksAndSkills;
    this.profile.tasksAndSkills = { ...tasksAndSkills, skills };
  }

  @Mutation
  SET_TALENT_EXPECTATIONS(v: TalentExpectations) {
    const newProfile: TalentProfileDto = {
      ...this.profile,
      expectations: v,
    };

    this.profile = newProfile;
  }

  talentOpportunities: JobbOfferVisualForView[] = [];

  @Mutation
  SET_TALENT_OPPORTUNITIES(v: JobbOfferVisualForView[]) {
    this.talentOpportunities = v;
  }

  get jobOffersInternal(): JobbOfferVisualForView[] {
    return this.jobOffersInternalInternal;
  }

  @Mutation
  SET_JOB_OFFERS(jobOffers: JobbOfferVisualForView[]) {
    this.jobOffersInternalInternal = jobOffers;
  }

  @Mutation
  SET_TALENT_PROFILE(profile: TalentProfileDto) {
    this.profile = profile;
  }

  @Action
  async addEmployment(obj: {
    employment: EmploymentToAdd;
    taskIds: string[];
  }): Promise<string> {
    const newEmploymentId = uuid();
    const experience: TalentExperience =
      this.profile.experience || defaultExperience;

    const newProfile: TalentProfileDto = {
      ...this.profile,
      expectations: {
        ...(this.profile.expectations || defaultExpectations),
        salary: (this.profile.expectations || defaultExpectations).salary || 0,
      },
      tasksAndSkills: {
        ...this.skills,
        roles: this.skills.roles
          .map(r => r.roleId)
          .includes(obj.employment.roleId)
          ? this.skills.roles.map(r => {
              if (r.roleId !== obj.employment.roleId) {
                return r;
              }
              return {
                ...r,
                taskIds: [...new Set([...r.taskIds, ...obj.taskIds])],
              };
            })
          : [
              ...this.skills.roles,
              { roleId: obj.employment.roleId, taskIds: obj.taskIds },
            ],
      },
      experience: {
        ...experience,
        employments: [
          ...experience.employments,
          { ...obj.employment, id: newEmploymentId },
        ].filter(
          e =>
            !!e.roleId &&
            profileLookupsStore.roleIds.includes(e.roleId) &&
            (e.branchId === null ||
              profileLookupsStore.branchIds.includes(e.branchId))
        ),
      },
    };

    const token = await TokenHelpers.getToken();

    const newProfileWithFixedStartDate: TalentProfileDto = FilterHelpers.getTalentProfileWithFixedStartDate(
      newProfile,
      new Date()
    );

    await talentProfileService.updateTalentProfile(
      newProfileWithFixedStartDate,
      token
    );

    talentProfileStore.SET_TALENT_PROFILE(newProfileWithFixedStartDate);
    talentProfileStore.SET_TALENT_OPPORTUNITIES_ARE_LOADED(false);

    return newEmploymentId;
  }

  @Action
  async updateName(obj: { firstName: string; lastName: string }) {
    const newProfile: TalentProfileDto = {
      ...this.profile,
      firstName: obj.firstName,
      lastName: obj.lastName,
    };

    const token = await TokenHelpers.getToken();

    await talentProfileService.updateTalentProfile(newProfile, token);

    talentProfileStore.SET_TALENT_PROFILE(newProfile);
  }

  get isPaused(): boolean {
    return this.profile.pausedTo != null && this.profile.pausedTo > new Date();
  }

  @Action
  async pauseTalent(v: { numberOfMonths: number }) {
    const newPauseDate = new Date();
    newPauseDate.setMonth(newPauseDate.getMonth() + v.numberOfMonths);
    const newProfile: TalentProfileDto = {
      ...this.profile,
      pausedTo: newPauseDate,
    };

    const token = await TokenHelpers.getToken();

    await talentProfileService.updateTalentProfile(newProfile, token);

    talentProfileStore.SET_TALENT_PROFILE(newProfile);
  }

  @Action
  async rejectCompany(v: { companyId: string }) {
    const expectations = this.profile.expectations || defaultExpectations;
    const newProfile: TalentProfileDto = {
      ...this.profile,
      expectations: {
        ...expectations,
        rejectedCompanyIds: [
          ...new Set([...expectations.rejectedCompanyIds, v.companyId]),
        ],
      },
    };

    const token = await TokenHelpers.getToken();

    await talentProfileService.updateTalentProfile(newProfile, token);

    talentProfileStore.SET_TALENT_PROFILE(newProfile);

    talentProfileStore.SET_TALENT_OPPORTUNITIES_ARE_LOADED(false);
  }

  @Action
  async unrejectCompany(v: { companyId: string }) {
    const expectations = this.profile.expectations || defaultExpectations;
    const newProfile: TalentProfileDto = {
      ...this.profile,
      expectations: {
        ...expectations,
        rejectedCompanyIds: expectations.rejectedCompanyIds.filter(
          x => x !== v.companyId
        ),
      },
    };

    const token = await TokenHelpers.getToken();

    await talentProfileService.updateTalentProfile(newProfile, token);

    talentProfileStore.SET_TALENT_PROFILE(newProfile);

    talentProfileStore.SET_TALENT_OPPORTUNITIES_ARE_LOADED(false);
  }

  @Action
  async updateRejectedCompanies(v: { companyIds: string[] }) {
    const expectations = this.profile.expectations || defaultExpectations;
    const newProfile: TalentProfileDto = {
      ...this.profile,
      expectations: {
        ...expectations,
        rejectedCompanyIds: v.companyIds,
      },
    };

    const token = await TokenHelpers.getToken();

    await talentProfileService.updateTalentProfile(newProfile, token);

    talentProfileStore.SET_TALENT_PROFILE(newProfile);
    talentProfileStore.SET_TALENT_OPPORTUNITIES_ARE_LOADED(false);
  }

  @Action
  async reactivateTalent() {
    const newProfile: TalentProfileDto = {
      ...this.profile,
      pausedTo: null,
      numberOfAutoDeclines: 0,
    };

    const token = await TokenHelpers.getToken();

    await talentProfileService.updateTalentProfile(newProfile, token);

    talentProfileStore.SET_TALENT_PROFILE(newProfile);
  }

  talentPools: GetTalentPoolsForTalentDtoWithDates[] = [];

  @Mutation
  SET_TALENT_POOLS(v: GetTalentPoolsForTalentDtoWithDates[]) {
    this.talentPools = v;
  }

  @Action
  async declinePoolFromTalent(v: { companyId: string }) {
    const token = await TokenHelpers.getToken();

    await TalentPoolService.declineTalentPoolFromTalent({
      accessToken: token,
      companyId: v.companyId,
    });

    talentProfileStore.SET_TALENT_POOLS(
      this.talentPools.map(tp => {
        if (tp.companyId === v.companyId) {
          const newTp: GetTalentPoolsForTalentDtoWithDates = {
            ...tp,
            declined: new Date(),
          };
          return newTp;
        }

        return tp;
      })
    );
  }

  @Action
  async addInterestToPool(v: { companyId: string; recruitmentId: string }) {
    const token = await TokenHelpers.getToken();

    await TalentPoolService.acceptTalentPoolFromTalent({
      accessToken: token,
      companyId: v.companyId,
      recruitmentId: v.recruitmentId,
    });

    const pool: GetTalentPoolsForTalentDtoWithDates = this.talentPools.find(
      x => x.companyId === v.companyId
    ) ?? {
      accepted: new Date(),
      bannerImageUrl: null,
      defaultBannerColor: null,
      branchId: null,
      cardImageUrl: null,
      companyId: v.companyId,
      companyName: null,
      declined: null,
      logoUrl: null,
      invited: null,
      removed: null,
      isRemoveInformed: false,
    };

    talentProfileStore.SET_TALENT_POOLS(
      SignalRHelper.getListWithNewItem({
        items: this.talentPools,
        getId: x => x.companyId,
        newItem: {
          ...pool,
          accepted: new Date(),
          declined: null,
        },
      })
    );
  }

  talentPoolsAreLoaded = false;

  @Mutation
  SET_TALENT_POOLS_ARE_LOADED(v: boolean) {
    this.talentPoolsAreLoaded = v;
  }

  @Action
  async loadTalentPools() {
    if (!this.talentPoolsAreLoaded) {
      const token = await TokenHelpers.getToken();
      const result = await TalentPoolService.getTalentPoolsForTalent({
        accessToken: token,
      });

      talentProfileStore.SET_TALENT_POOLS(result);

      talentProfileStore.SET_TALENT_POOLS_ARE_LOADED(true);
    }
  }

  @Action
  async setIsInformedOnTalentPool(v: { companyId: string }) {
    const token = await TokenHelpers.getToken();

    await TalentPoolService.setIsInformedOnTalentPool({
      accessToken: token,
      companyId: v.companyId,
    });

    talentProfileStore.SET_TALENT_POOLS(
      this.talentPools.map(p => {
        if (p.companyId === v.companyId) {
          return {
            ...p,
            isRemoveInformed: true,
          };
        }
        return p;
      })
    );
  }

  @Action
  async updateContactInfo(v: {
    email: string | null;
    phoneNumber: string | null;
  }) {
    const newProfile: TalentProfileDto = {
      ...this.profile,
      contactEmail: v.email,
      phoneNumber: v.phoneNumber,
    };

    const token = await TokenHelpers.getToken();

    await talentProfileService.updateTalentProfile(newProfile, token);

    talentProfileStore.SET_TALENT_PROFILE(newProfile);
  }

  @Action
  async resetNumberOfAutoDeclines() {
    const newProfile: TalentProfileDto = {
      ...this.profile,
      numberOfAutoDeclines: 0,
    };

    const token = await TokenHelpers.getToken();

    await talentProfileService.updateTalentProfile(newProfile, token);
    talentProfileStore.SET_TALENT_PROFILE(newProfile);
  }

  @Action
  async updateContactInfoPublicOld(v: {
    email: string | null;
    phoneNumber: string | null;
    token: string;
  }) {
    await JobOfferPublicService.updateTalentContactInfoOld({
      contactEmail: v.email,
      phoneNumber: v.phoneNumber,
      token: v.token,
    });

    const newProfile: TalentProfileDto = {
      ...this.profile,
      contactEmail: v.email,
      phoneNumber: v.phoneNumber,
    };

    talentProfileStore.SET_TALENT_PROFILE(newProfile);
  }

  @Action
  async updateContactInfoPublic(v: {
    email: string | null;
    phoneNumber: string | null;
    recruitmentId: string;
    token: string;
  }) {
    await JobOfferPublicService.updateTalentContactInfo({
      contactEmail: v.email,
      phoneNumber: v.phoneNumber,
      token: v.token,
    });

    talentProfileStore.loadTalentJobOfferPublic({
      recruitmentId: v.recruitmentId,
      token: v.token,
      force: true,
    });
  }

  @Action
  async updateTalentLanguage(obj: { lang: string }) {
    const token = await TokenHelpers.getToken();

    await talentProfileService.updateTalentLanguage({
      lang: obj.lang,
      accessToken: token,
    });

    // talentProfileStore.loadTalentProfile(obj.lang);
  }

  @Action
  async updateUnderstandRespondImportance() {
    const newProfile: TalentProfileDto = {
      ...this.profile,
      understandRespondImportance: true,
    };

    const token = await TokenHelpers.getToken();

    await talentProfileService.updateTalentProfile(newProfile, token);

    talentProfileStore.SET_TALENT_PROFILE(newProfile);
  }

  @Action
  async updateEmployment(obj: {
    employment: EmploymentSaved;
    taskIds: string[];
  }) {
    const experience: TalentExperience =
      this.profile.experience || defaultExperience;

    const newEmployments = experience.employments
      .map(x => {
        if (x.id === obj.employment.id) {
          return obj.employment;
        }
        return x;
      })
      .filter(
        e =>
          !!e.roleId &&
          profileLookupsStore.roleIds.includes(e.roleId) &&
          (e.branchId === null ||
            profileLookupsStore.branchIds.includes(e.branchId))
      );

    const askForBranchExperience = TalentHelpers.getAskForBranchExperience(
      profileLookupsStore.roles,
      newEmployments
    );

    const newTaskRoles = this.skills.roles
      .map(r => r.roleId)
      .includes(obj.employment.roleId)
      ? this.skills.roles.map(r => {
          if (r.roleId !== obj.employment.roleId) {
            return r;
          }
          return {
            ...r,
            taskIds: [...new Set([...r.taskIds, ...obj.taskIds])],
          };
        })
      : [
          ...this.skills.roles,
          { roleId: obj.employment.roleId, taskIds: obj.taskIds },
        ];
    const newProfile: TalentProfileDto = {
      ...this.profile,
      expectations: {
        ...(this.profile.expectations || defaultExpectations),
        salary: (this.profile.expectations || defaultExpectations).salary || 0,
        requireStableSalary:
          (this.profile.expectations || defaultExpectations)
            .requireStableSalary || false,
      },
      tasksAndSkills: {
        ...this.skills,
        roles: newTaskRoles.filter(r =>
          newEmployments.some(e => e.roleId === r.roleId)
        ),
      },
      experience: {
        ...experience,
        employments: newEmployments,
        branchIds: askForBranchExperience ? experience.branchIds : [],
      },
    };

    const token = await TokenHelpers.getToken();

    const newProfileWithFixedStartDate: TalentProfileDto = FilterHelpers.getTalentProfileWithFixedStartDate(
      newProfile,
      new Date()
    );

    await talentProfileService.updateTalentProfile(
      newProfileWithFixedStartDate,
      token
    );

    talentProfileStore.SET_TALENT_PROFILE(newProfileWithFixedStartDate);
    talentProfileStore.SET_TALENT_OPPORTUNITIES_ARE_LOADED(false);
  }

  @Action
  async addEducation(education: EducationToAdd) {
    const experience: TalentExperience =
      this.profile.experience || defaultExperience;
    const newProfile: TalentProfileDto = {
      ...this.profile,
      experience: {
        ...experience,
        hasNoEducation: false,
        education: [...experience.education, { ...education, id: uuid() }],
      },
    };

    const token = await TokenHelpers.getToken();

    await talentProfileService.updateTalentProfile(newProfile, token);

    talentProfileStore.SET_TALENT_PROFILE(newProfile);
  }

  @Action
  async updateHasNoEducation(hasNoEducation: boolean) {
    const experience: TalentExperience =
      this.profile.experience || defaultExperience;

    const newProfile: TalentProfileDto = {
      ...this.profile,
      experience: {
        ...experience,
        hasNoEducation,
      },
    };

    const token = await TokenHelpers.getToken();

    await talentProfileService.updateTalentProfile(newProfile, token);

    talentProfileStore.SET_TALENT_PROFILE(newProfile);
    talentProfileStore.SET_TALENT_OPPORTUNITIES_ARE_LOADED(false);
  }

  @Action
  async updateEducation(education: EducationSaved) {
    const experience: TalentExperience =
      this.profile.experience || defaultExperience;
    const newProfile: TalentProfileDto = {
      ...this.profile,
      experience: {
        ...experience,
        hasNoEducation: false,
        education: experience.education.map(x => {
          if (x.id === education.id) {
            return education;
          }
          return x;
        }),
      },
    };

    const token = await TokenHelpers.getToken();

    await talentProfileService.updateTalentProfile(newProfile, token);

    talentProfileStore.SET_TALENT_PROFILE(newProfile);
    talentProfileStore.SET_TALENT_OPPORTUNITIES_ARE_LOADED(false);
  }

  @Action
  async removeEducation(id: string) {
    const experience: TalentExperience =
      this.profile.experience || defaultExperience;
    const newProfile: TalentProfileDto = {
      ...this.profile,
      experience: {
        ...experience,
        education: experience.education.filter(e => e.id !== id),
      },
    };

    const token = await TokenHelpers.getToken();

    await talentProfileService.updateTalentProfile(newProfile, token);

    talentProfileStore.SET_TALENT_PROFILE(newProfile);
    talentProfileStore.SET_TALENT_OPPORTUNITIES_ARE_LOADED(false);
  }

  @Action
  async updateTasksAndSkills(
    updateTasksAndSkillsRequest: TalentTasksAndSkills
  ) {
    const newProfile: TalentProfileDto = {
      ...this.profile,
      tasksAndSkills: updateTasksAndSkillsRequest,
    };

    const token = await TokenHelpers.getToken();

    await talentProfileService.updateTalentProfile(newProfile, token);

    talentProfileStore.SET_TALENT_PROFILE(newProfile);
    talentProfileStore.SET_TALENT_OPPORTUNITIES_ARE_LOADED(false);
  }

  @Action
  async updateExpectations(request: TalentExpectations) {
    const newProfile: TalentProfileDto = {
      ...this.profile,
      expectations: request,
    };

    const token = await TokenHelpers.getToken();

    await talentProfileService.updateTalentProfile(newProfile, token);

    talentProfileStore.SET_TALENT_PROFILE(newProfile);
    talentProfileStore.SET_TALENT_OPPORTUNITIES_ARE_LOADED(false);
  }

  @Action
  async updateTalentProfile(newProfile: TalentProfileDto) {
    const token = await TokenHelpers.getToken();

    const newProfileWithFixedStartDate: TalentProfileDto = FilterHelpers.getTalentProfileWithFixedStartDate(
      newProfile,
      new Date()
    );

    await talentProfileService.updateTalentProfile(
      newProfileWithFixedStartDate,
      token
    );

    talentProfileStore.SET_TALENT_PROFILE(newProfileWithFixedStartDate);
    talentProfileStore.SET_TALENT_OPPORTUNITIES_ARE_LOADED(false);
  }

  @Action
  async updateExperience(obj: UpdateExperienceRequest) {
    const talentExperience: TalentExperience = {
      ...this.profile.experience!,
      ...obj,
    };

    const newProfile: TalentProfileDto = {
      ...this.profile,
      expectations: {
        ...(this.profile.expectations || defaultExpectations),
        salary: (this.profile.expectations || defaultExpectations).salary || 0,
      },
      experience: talentExperience,
    };

    const token = await TokenHelpers.getToken();

    await talentProfileService.updateTalentProfile(newProfile, token);

    talentProfileStore.SET_TALENT_PROFILE(newProfile);
    talentProfileStore.SET_TALENT_OPPORTUNITIES_ARE_LOADED(false);
  }

  loadedRecruitmentId: string | null = null;

  @Mutation
  SET_LOADED_RECRUITMENT_ID(v: string | null) {
    this.loadedRecruitmentId = v;
  }

  messagesByRecruitmentId: Record<string, ThingToLoad<TalentMessage[]>> = {};

  @Mutation
  SET_MESSAGES_BY_RECRUITMENT_ID(v: {
    recruitmentId: string;
    messages: ThingToLoad<TalentMessage[]>;
  }) {
    this.messagesByRecruitmentId = {
      ...this.messagesByRecruitmentId,
      [v.recruitmentId]: v.messages,
    };
  }

  @Action
  async loadTalentMessagesForTalent(recruitmentId: string) {
    const thingToLoad = TokenHelpers.getThingToLoadOrExit(
      this.messagesByRecruitmentId[recruitmentId] ?? {
        type: "notFetched",
      },
      false
    );

    if (thingToLoad.type === "exit") {
      return;
    }

    talentProfileStore.SET_MESSAGES_BY_RECRUITMENT_ID({
      recruitmentId,
      messages: thingToLoad,
    });

    const token = await TokenHelpers.getToken();
    const messages = await talentProfileService.getTalentMessagesForTalent(
      recruitmentId,
      token
    );

    talentProfileStore.SET_MESSAGES_BY_RECRUITMENT_ID({
      recruitmentId,
      messages: { type: "loaded", value: messages },
    });

    talentProfileStore.SET_LOADED_RECRUITMENT_ID(recruitmentId);
  }

  @Action
  async addMessageFromTalent(obj: { message: string; recruitmentId: string }) {
    const token = await TokenHelpers.getToken();
    const newMessageId = await talentProfileService.addMessageFromTalent(
      obj.message,
      obj.recruitmentId,
      token
    );

    const newMessage: TalentMessage = {
      comment: obj.message,
      date: new Date(),
      fromTalent: true,
      id: newMessageId,
      sender: {
        firstName: this.profile.firstName || "",
        lastName: this.profile.lastName || "",
        userId: authStore.userId!,
      },
    };

    const loadedMessages = this.messagesByRecruitmentId[obj.recruitmentId] ?? {
      type: "notFetched",
    };

    if (loadedMessages.type === "loaded") {
      this.SET_MESSAGES_BY_RECRUITMENT_ID({
        recruitmentId: obj.recruitmentId,
        messages: {
          type: "loaded",
          value: SignalRHelper.getListWithNewItem({
            items: loadedMessages.value,
            getId: x => x.id,
            newItem: newMessage,
          }),
        },
      });
    }
  }

  matchesByRecruitmentCompanyId: Record<string, string[]> = {};

  @Mutation
  SET_MATCHES_FOR_RECRUITMENT(v: {
    recruitmentId: string;
    companyId: string;
    matches: string[];
  }) {
    this.matchesByRecruitmentCompanyId = {
      ...this.matchesByRecruitmentCompanyId,
      [`${v.recruitmentId}|${v.companyId}`]: v.matches,
    };
  }

  @Action
  async updateDenySms(v: { denySms: boolean }) {
    const token = await TokenHelpers.getToken();
    await talentProfileService.updateTalentUser({
      updateTalentUserDto: {
        denySms: v.denySms,
      },
      accessToken: token,
    });
    if (talentProfileStore.talentUserObject) {
      talentProfileStore.SET_TALENT_USER_OBJECT({
        ...talentProfileStore.talentUserObject,
        denySms: v.denySms,
      });
    }
  }

  @Action
  async loadMatchingPreferencesForTalent(v: {
    recruitmentId: string;
    companyId: string;
  }) {
    const existing =
      this.matchesByRecruitmentCompanyId[`${v.recruitmentId}|${v.companyId}`] ??
      null;
    if (existing === null) {
      talentProfileStore.SET_MATCHES_FOR_RECRUITMENT({
        companyId: v.companyId,
        recruitmentId: v.recruitmentId,
        matches: [],
      });
      const token = await TokenHelpers.getToken();
      const result = await talentProfileService.getMatchingPreferencesForTalent(
        {
          accessToken: token,
          companyId: v.companyId,
          recruitmentId: v.recruitmentId,
        }
      );
      // const result = await new Promise<string[]>(resolve => {
      //   resolve([]);
      // });

      talentProfileStore.SET_MATCHES_FOR_RECRUITMENT({
        companyId: v.companyId,
        recruitmentId: v.recruitmentId,
        matches: result,
      });
    }
  }

  companyProfilesByCompanyId: Record<
    string,
    ThingToLoad<PublishedCompoanyProfileDto>
  > = {};

  publicTalentInfo: JobOfferTalentInfo | null = null;

  @Mutation
  SET_PUBLIC_TALENT_INFO(v: JobOfferTalentInfo | null) {
    this.publicTalentInfo = v;
  }

  publicJobOffersByRecruitmentId: Record<
    string,
    ThingToLoad<{
      jobOffer: JobbOfferVisualForView;
      companyProfile: PublishedCompoanyProfileDto;
      matchedPreferences: string[];
    }>
  > = {};

  @Mutation
  SET_PUBLIC_JOB_OFFER_BY_RECRUITMENT_ID(v: {
    recruitmentId: string;
    value: ThingToLoad<{
      jobOffer: JobbOfferVisualForView;
      companyProfile: PublishedCompoanyProfileDto;
      matchedPreferences: string[];
    }>;
  }) {
    this.publicJobOffersByRecruitmentId = {
      ...this.publicJobOffersByRecruitmentId,
      [v.recruitmentId]: v.value,
    };
  }

  @Mutation
  SET_COMPANY_PROFILE_BY_COMPANY_ID(v: {
    companyId: string;
    profileToLoad: ThingToLoad<PublishedCompoanyProfileDto>;
  }) {
    this.companyProfilesByCompanyId = {
      ...this.companyProfilesByCompanyId,
      [v.companyId]: v.profileToLoad,
    };
  }

  @Action
  async loadCompanyProfileById(companyId: string) {
    const thingToLoad = TokenHelpers.getThingToLoadOrExit(
      this.companyProfilesByCompanyId[companyId] ?? {
        type: "notFetched",
      },
      false
    );

    if (thingToLoad.type === "exit") {
      return;
    }

    talentProfileStore.SET_COMPANY_PROFILE_BY_COMPANY_ID({
      companyId,
      profileToLoad: thingToLoad,
    });

    const token = await TokenHelpers.getToken();
    const profile = await talentProfileService.getCompanyProfileById(
      companyId,
      token
    );

    talentProfileStore.SET_COMPANY_PROFILE_BY_COMPANY_ID({
      companyId,
      profileToLoad: { type: "loaded", value: profile },
    });

    talentProfileStore.SET_COMPANY_PROFILE_BY_ID({
      logoUrl: profile.logoUrl,
      companyEmail: profile.companyEmail,
      companyName: profile.companyName,
      ingress: profile.ingress,
      logoIncludesName: profile.logoIncludesName,
      branchId: profile.branchId,
      industryId: profile.industryId,
      isStartUpScaleUp: profile.isStartUpScaleUp,
      isPublicSector: profile.isPublicSector,
      isConsultingCompany: profile.isConsultingCompany,
      businessAreasType:
        profile.businessAreasType || BusinessAreasType.BusinessAreas,
      atAGlance: {
        message: profile.message,
        inShort: profile.inShort,
        socialMedia: profile.socialMedia,
      },
      company: {
        companyVideoUrl: profile.companyVideoUrl,
        companyVideoType: profile.companyVideoType || "Youtube",
        businessAreas: profile.businessAreas,
        values: {
          valuesVisible: profile.valuesVisible,
          valuesImageUrl: profile.valuesImageUrl,
          valueList: profile.values,
        },
        milestones: {
          milestonesVisible: profile.milestonesVisible,
          milestoneList: profile.milestones,
        },
      },
      people: {
        statistics: profile.statistics,
        team: profile.team,
        teamVisible: profile.teamVisible,
        perks: {
          perksImageUrl: profile.perksImageUrl,
          perksVisible: profile.perksVisible,
          perkList: profile.perks,
        },
        roles: {
          visible: profile.rolesVisible,
          roleList: profile.roles,
        },
        offices: profile.offices.map(o => ({
          description: o.description || "",
          id: o.id,
          name: o.name,
          officeImage: o.officeImage,
          latitude: o.latitude,
          longitude: o.longitude,
        })),
      },
    });
  }

  @Action
  async setProcessRead(v: { recruitmentId: string }) {
    const token = await TokenHelpers.getToken();

    await talentProfileService.setProcessRead({
      recruitmentId: v.recruitmentId,
      token,
    });

    talentProfileStore.SET_JOB_OFFERS(
      this.jobOffers.map(jo => {
        if (jo.recruitmentId === v.recruitmentId) {
          return {
            ...jo,
            lastRead: new Date(),
          };
        }
        return jo;
      })
    );
  }

  @Action
  async setProcessReadPublicOld(v: { token: string }) {
    await JobOfferPublicService.UpdateLastReadPublicOld({
      token: v.token,
    });

    talentProfileStore.SET_JOB_OFFERS(
      this.jobOffers.map(jo => {
        return {
          ...jo,
          lastRead: new Date(),
        };
      })
    );
  }

  @Action
  async setProcessReadPublic(v: { token: string; recruitmentId: string }) {
    await JobOfferPublicService.UpdateLastReadPublic({
      token: v.token,
      recruitmentId: v.recruitmentId,
    });

    talentProfileStore.SET_JOB_OFFERS(
      this.jobOffers.map(jo => {
        return {
          ...jo,
          lastRead: new Date(),
        };
      })
    );
  }

  isConnectedToSignalR = false;

  @Mutation
  SET_IS_CONNECTED_TO_SIGNAL_R(v: boolean) {
    this.isConnectedToSignalR = v;
  }

  isDisconnected = false;

  @Mutation
  SET_IS_SIDCONNECTED(v: boolean) {
    this.isDisconnected = v;
  }

  @Action
  async connectSignalR() {
    const token = await TokenHelpers.getToken();

    if (!this.isConnectedToSignalR) {
      const talentUrl =
        process.env.ENVIRONMENT === "Production"
          ? "https://realtime.gritify.io/api"
          : process.env.ENVIRONMENT === "Stage"
          ? "https://realtime-stage.gritify.io/api"
          : "https://gritify-signalr-api-test.azurewebsites.net/api";

      if ($msal.data) {
        talentProfileStore.SET_IS_CONNECTED_TO_SIGNAL_R(true);
        const connection = new signalR.HubConnectionBuilder()
          .withUrl(`${talentUrl}`, {
            // .withUrl("https://gritify-signalr-api-test.azurewebsites.net/api", {
            accessTokenFactory: () => {
              return token;
            },
          })
          .configureLogging(signalR.LogLevel.Information)
          .withAutomaticReconnect()
          .build();

        const userId = authStore.userId!;

        connection.on(userId, (data: SignalRForTalent) => {
          SignalRHelper.handleMessageForTalent(data);
        });

        connection.onreconnecting(_ => {
          talentProfileStore.SET_IS_SIDCONNECTED(true);
        });

        connection.onreconnected(_ => {
          // companyBaseStore.SET_IS_SIDCONNECTED(false);
        });

        connection.onclose(() => console.log("signal r talent disconnected"));

        connection
          .start()
          .then(() => console.log("signal r talent startad"))
          .catch(err => console.error("nått gick fel att starta", err));
      }
    }
  }

  jobOffersAreLoaded = false;

  @Mutation
  SET_JOBOFFERS_ARE_LOADED(v: boolean) {
    this.jobOffersAreLoaded = v;
  }

  talentOpportunitiesAreLoaded = false;

  @Mutation
  SET_TALENT_OPPORTUNITIES_ARE_LOADED(v: boolean) {
    this.talentOpportunitiesAreLoaded = v;
  }

  inviterId: string | null = null;
  @Mutation
  SET_INVITER_ID(v: string | null) {
    this.inviterId = v;
  }

  talentIsInPublicCompanyPool = false;

  @Mutation
  SET_TALENT_IS_IN_PUBLIC_COMPANY_POOL(v: boolean) {
    this.talentIsInPublicCompanyPool = v;
  }

  @Action
  async loadTalentJobOfferPublicOld(v: { token: string }): Promise<void> {
    let response: PublicJobOfferDto | null = null;
    try {
      response = await JobOfferPublicService.getTalentJobOfferPublicOld({
        token: v.token,
      });
    } catch (error) {}

    if (!response) {
      return;
    }

    const mappedJobOffer = TalentHelpers.mapToJobOfferToView(response.jobOffer);

    talentProfileStore.SET_TALENT_IS_IN_PUBLIC_COMPANY_POOL(
      response.talentIsInPool
    );

    if (mappedJobOffer !== null) {
      talentProfileStore.SET_JOB_OFFERS([mappedJobOffer]);

      const profile = response.companyProfile;

      talentProfileStore.SET_COMPANY_PROFILE_BY_ID(
        CompanyProfileHelper.MapToCompanyProfileDto(profile)
      );

      talentProfileStore.SET_TALENT_PROFILE({
        ...this.profile,
        firstName: response.talentInfo.firstName,
        lastName: response.talentInfo.lastName,
        contactEmail: response.talentInfo.contactEmail,
        phoneNumber: response.talentInfo.phoneNumber,
        understandRespondImportance:
          response.talentInfo.understandRespondImportance,
      });

      talentProfileStore.SET_INVITER_ID(response.talentInfo.inviterId);

      talentProfileStore.SET_MATCHES_FOR_RECRUITMENT({
        companyId: response.jobOffer.jobOffer.companyId,
        recruitmentId: response.jobOffer.recruitmentId,
        matches: [
          ...response.emailPreferences.column1,
          ...response.emailPreferences.column2,
        ],
      });
    }
  }

  isInPoolPublic = false;

  @Mutation
  SET_IS_IN_POOL_PUBLIC(v: boolean) {
    this.isInPoolPublic = v;
  }

  @Action
  async loadTalentJobOfferPublic(v: {
    token: string;
    recruitmentId: string;
    force?: boolean;
  }): Promise<void> {
    const thingToLoad = TokenHelpers.getThingToLoadOrExit(
      this.publicJobOffersByRecruitmentId[v.recruitmentId] ?? {
        type: "notFetched",
      },
      v.force ?? false
    );

    if (thingToLoad.type === "exit") {
      return;
    }

    talentProfileStore.SET_PUBLIC_JOB_OFFER_BY_RECRUITMENT_ID({
      recruitmentId: v.recruitmentId,
      value: thingToLoad,
    });

    try {
      const result = await JobOfferPublicService.getTalentJobOfferPublic({
        token: v.token,
        recruitmentId: v.recruitmentId,
      });

      const otherJobOffers = result.otherJobOffers.reduce(
        (acc: JobbOfferVisualForView[], jo) => {
          const mapped = TalentHelpers.mapToJobOfferToView(jo);

          if (mapped) {
            return [...acc, mapped!];
          }

          return acc;
        },
        []
      );

      talentProfileStore.SET_OTHER_PUBLIC_JOB_OFFERS(otherJobOffers);

      const jobOffer = TalentHelpers.mapToJobOfferToView(result.jobOffer);

      talentProfileStore.SET_PUBLIC_TALENT_INFO({
        contactEmail: result.contactEmail,
        firstName: result.firstName,
        lastName: result.lastName,
        phoneNumber: result.phoneNumber,
        salary: result.salary ?? 0,
        expectations: result.expectations,
        inviterId: result.inviterId,
      });

      if (jobOffer) {
        talentProfileStore.SET_PUBLIC_JOB_OFFER_BY_RECRUITMENT_ID({
          recruitmentId: v.recruitmentId,
          value: {
            type: "loaded",
            value: {
              companyProfile: result.companyProfile,
              jobOffer,
              matchedPreferences: [
                ...result.emailPreferences.column1,
                ...result.emailPreferences.column2,
              ],
            },
          },
        });
      }
    } catch (error) {}
  }

  // @Action
  // async loadTalentJobOfferPublic(v: {
  //   token: string;
  //   recruitmentId: string;
  // }): Promise<void> {
  //   let response: GetPublicTalentJobOfferDto | null = null;
  //   try {
  //     response = await JobOfferPublicService.getTalentJobOfferPublic({
  //       token: v.token,
  //       recruitmentId: v.recruitmentId,
  //     });
  //   } catch (error) {}

  //   if (!response) {
  //     return;
  //   }

  //   const mappedJobOffer = TalentHelpers.mapToJobOfferToView(response.jobOffer);

  //   talentProfileStore.SET_IS_IN_POOL_PUBLIC(response.isInPool);

  //   const otherJobOffers = response.otherJobOffers.reduce(
  //     (acc: JobbOfferVisualForView[], jo) => {
  //       const mapped = TalentHelpers.mapToJobOfferToView(jo);

  //       if (mapped) {
  //         return [...acc, mapped!];
  //       }

  //       return acc;
  //     },
  //     []
  //   );

  //   talentProfileStore.SET_OTHER_PUBLIC_JOB_OFFERS(otherJobOffers);

  //   if (mappedJobOffer !== null) {
  //     talentProfileStore.SET_JOB_OFFERS([mappedJobOffer]);

  //     const profile = response.companyProfile;

  //     talentProfileStore.SET_COMPANY_PROFILE_BY_ID({
  //       logoUrl: profile.logoUrl,
  //       companyEmail: profile.companyEmail,
  //       companyName: profile.companyName,
  //       ingress: profile.ingress,
  //       logoIncludesName: profile.logoIncludesName,
  //       branchId: profile.branchId,
  //       industryId: profile.industryId,
  //       isConsultingCompany: profile.isConsultingCompany,
  //       isPublicSector: profile.isPublicSector,
  //       isStartUpScaleUp: profile.isStartUpScaleUp,
  //       businessAreasType:
  //         profile.businessAreasType || BusinessAreasType.BusinessAreas,
  //       atAGlance: {
  //         message: profile.message,
  //         inShort: profile.inShort,
  //         socialMedia: profile.socialMedia,
  //       },
  //       company: {
  //         companyVideoUrl: profile.companyVideoUrl,
  //         companyVideoType: profile.companyVideoType || "Youtube",
  //         businessAreas: profile.businessAreas,
  //         values: {
  //           valuesVisible: profile.valuesVisible,
  //           valuesImageUrl: profile.valuesImageUrl,
  //           valueList: profile.values,
  //         },
  //         milestones: {
  //           milestonesVisible: profile.milestonesVisible,
  //           milestoneList: profile.milestones,
  //         },
  //       },
  //       people: {
  //         statistics: profile.statistics,
  //         team: profile.team,
  //         teamVisible: profile.teamVisible,
  //         perks: {
  //           perksImageUrl: profile.perksImageUrl,
  //           perksVisible: profile.perksVisible,
  //           perkList: profile.perks,
  //         },
  //         roles: {
  //           visible: profile.rolesVisible,
  //           roleList: profile.roles,
  //         },
  //         offices: profile.offices.map(o => ({
  //           description: o.description || "",
  //           id: o.id,
  //           name: o.name,
  //           officeImage: o.officeImage,
  //           latitude: o.latitude,
  //           longitude: o.longitude,
  //         })),
  //       },
  //     });

  //     talentProfileStore.SET_TALENT_PROFILE({
  //       ...this.profile,
  //       firstName: response.firstName,
  //       lastName: response.lastName,
  //       contactEmail: response.contactEmail,
  //       phoneNumber: response.phoneNumber,
  //       expectations: {
  //         ...(this.profile.expectations ?? defaultExpectations),
  //         salary: response.salary ?? 0,
  //       },
  //     });

  //     talentProfileStore.SET_MATCHES_FOR_RECRUITMENT({
  //       companyId: response.jobOffer.jobOffer.companyId,
  //       recruitmentId: response.jobOffer.recruitmentId,
  //       matches: [
  //         ...response.emailPreferences.column1,
  //         ...response.emailPreferences.column2,
  //       ],
  //     });
  //   }
  // }

  @Action
  async loadTalentJobOffers(): Promise<void> {
    if (!this.jobOffersAreLoaded) {
      const token = await TokenHelpers.getToken();

      const jobOffers = await talentProfileService.getTalentJobOffers(token);

      const realJobOffers = jobOffers.filter(jo => jo.invited || jo.accepted);

      const jobOffersCopy: JobbOfferVisualForView[] = [...realJobOffers];

      talentProfileStore.SET_JOB_OFFERS(jobOffersCopy);
      talentProfileStore.SET_JOBOFFERS_ARE_LOADED(true);
    }
  }

  @Action
  async acceptOpportunity(obj: { recruitmentId: string; companyId: string }) {
    if (!this.profile.firstName || !this.profile.lastName) {
      notificationsStore.setWarningMessage("You must save a name first!");
    }

    const token = await TokenHelpers.getToken();
    await talentProfileService.acceptOpportunity(
      obj.companyId,
      obj.recruitmentId,
      `${this.profile.firstName!} ${this.profile.lastName!}`,
      token
    );

    await talentProfileStore.resetNumberOfAutoDeclines();

    const oppJobOffer = this.talentOpportunities.find(
      x => x.recruitmentId === obj.recruitmentId
    );
    const realJobOffer = this.jobOffersInternalInternal.find(
      x => x.recruitmentId === obj.recruitmentId
    );

    const jobOffer = realJobOffer || oppJobOffer;

    if (jobOffer) {
      const newJobOffers = SignalRHelper.getListWithNewItem({
        items: this.jobOffersInternalInternal,
        getId: x => x.recruitmentId,
        newItem: {
          ...jobOffer,
          declined: null,
          accepted: new Date(),
          invited: null,
          salaryMatched: true,
        },
      });
      talentProfileStore.SET_JOB_OFFERS(newJobOffers);
    }
  }

  @Action
  async acceptInvite(obj: { recruitmentId: string; companyId: string }) {
    if (!this.profile.firstName || !this.profile.lastName) {
      notificationsStore.setWarningMessage("Du måste spara ett namn först!");
    }

    const token = await TokenHelpers.getToken();
    await talentProfileService.acceptInvite(
      obj.companyId,
      obj.recruitmentId,
      `${this.profile.firstName!} ${this.profile.lastName!}`,
      token
    );

    await talentProfileStore.resetNumberOfAutoDeclines();

    talentProfileStore.SET_JOB_OFFERS(
      this.jobOffersInternalInternal.map(jo => {
        if (jo.recruitmentId === obj.recruitmentId) {
          return { ...jo, accepted: new Date() };
        }
        return jo;
      })
    );
  }

  @Action
  async acceptInvitePublicOld(v: {
    token: string;
    firstName: string;
    lastName: string;
  }) {
    await JobOfferPublicService.acceptInvitePublicOld({
      firstName: v.firstName,
      lastName: v.lastName,
      token: v.token,
    });

    talentProfileStore.SET_JOB_OFFERS(
      this.jobOffers.map(jo => {
        return { ...jo, accepted: new Date() };
      })
    );
  }

  @Action
  async acceptInvitePublic(v: {
    token: string;
    firstName: string;
    lastName: string;
    recruitmentId: string;
    companyId: string;
  }) {
    await JobOfferPublicService.acceptInvitePublic({
      firstName: v.firstName,
      lastName: v.lastName,
      token: v.token,
      companyId: v.companyId,
      recruitmentId: v.recruitmentId,
    });

    talentProfileStore.loadTalentJobOfferPublic({
      recruitmentId: v.recruitmentId,
      token: v.token,
      force: true,
    });
  }

  @Action
  async declineInvitePublicOld(v: {
    token: string;
    declineReasonIds: string[];
    otherReason: string | null;
  }) {
    await JobOfferPublicService.declinePublicJobOfferOld({
      declineReasonIds: v.declineReasonIds,
      otherReason: v.otherReason,
      token: v.token,
    });

    talentProfileStore.SET_JOB_OFFERS(
      this.jobOffers.map(jo => {
        return { ...jo, declined: new Date() };
      })
    );
  }

  @Action
  async declineInvitePublic(v: {
    recruitmentId: string;
    companyId: string;
    token: string;
    declineReasonIds: string[];
    declineReasonTypes: string[];
    ratingCompanyProcent: number;
    ratingJobProcent: number;
    otherReason: string | null;
  }) {
    await JobOfferPublicService.declinePublicJobOffer({
      declineReasonIds: v.declineReasonIds,
      otherReason: v.otherReason,
      token: v.token,
      companyId: v.companyId,
      recruitmentId: v.recruitmentId,
      declineReasonTypes: v.declineReasonTypes,
      ratingJobProcent: v.ratingJobProcent,
      ratingCompanyProcent: v.ratingCompanyProcent,
    });

    talentProfileStore.loadTalentJobOfferPublic({
      recruitmentId: v.recruitmentId,
      token: v.token,
      force: true,
    });
  }

  @Action
  async updateJobOfferDeclineReasonsOld(v: {
    token: string;
    declineReasonIds: string[];
    otherReason: string | null;
  }) {
    await JobOfferPublicService.updateJobOfferDeclineReasonsOld({
      declineReasonIds: v.declineReasonIds,
      otherReason: v.otherReason,
      token: v.token,
    });
  }

  @Action
  async updateJobOfferDeclineReasons(v: {
    token: string;
    recruitmentId: string;
    declineReasonIds: string[];
    declineReasonTypes: string[];
    ratingJobProcent: number;
    ratingCompanyProcent: number;
    otherReason: string | null;
  }) {
    await JobOfferPublicService.updateJobOfferDeclineReasons({
      declineReasonIds: v.declineReasonIds,
      declineReasonTypes: v.declineReasonTypes,
      otherReason: v.otherReason,
      token: v.token,
      recruitmentId: v.recruitmentId,
      ratingJobProcent: v.ratingJobProcent,
      ratingCompanyProcent: v.ratingCompanyProcent,
    });
  }

  @Action
  async declineInvite(obj: {
    recruitmentId: string;
    companyId: string;
    declineReasonIds: string[];
    declineReasonTypes: string[];
    declineReason: string | null;
    ratingCompanyProcent: number;
    ratingJobProcent: number;
  }) {
    const token = await TokenHelpers.getToken();

    await talentProfileService.declineInvite(
      obj.companyId,
      obj.recruitmentId,
      token,
      obj.declineReasonIds,
      obj.declineReasonTypes,
      obj.declineReason,
      obj.ratingCompanyProcent,
      obj.ratingJobProcent
    );

    await talentProfileStore.resetNumberOfAutoDeclines();

    const oppJobOffer = this.talentOpportunities.find(
      x => x.recruitmentId === obj.recruitmentId
    );
    const realJobOffer = this.jobOffersInternalInternal.find(
      x => x.recruitmentId === obj.recruitmentId
    );

    const jobOffer = realJobOffer || oppJobOffer;

    if (jobOffer) {
      const newJobOffers = SignalRHelper.getListWithNewItem({
        items: this.jobOffersInternalInternal,
        getId: x => x.recruitmentId,
        newItem: { ...jobOffer, declined: new Date() },
      });
      talentProfileStore.SET_JOB_OFFERS(newJobOffers);
    }
  }

  @Action
  async loadTalentProfile(lang: string) {
    if (this.talentProfileIsLoaded !== lang) {
      const token = await TokenHelpers.getToken();

      try {
        const dto = await talentProfileService.getTalentProfile(token, lang);

        talentProfileStore.SET_TALENT_PROFILE_LOADED(lang);

        talentProfileStore.SET_TALENT_USER_OBJECT(dto.talentUser);

        talentProfileStore.SET_TALENT_PROFILE({
          ...dto.talentProfileObject,
          tasksAndSkills: dto.talentProfileObject.tasksAndSkills
            ? { ...dto.talentProfileObject.tasksAndSkills, hiddenRoleIds: [] }
            : null,
        });
      } catch (error) {
        notificationsStore.setErrorMessage({ error });
        throw error;
      }
    }
  }

  get expectations(): TalentExpectations {
    return this.profile.expectations || defaultExpectations;
  }

  get skills(): TalentTasksAndSkills {
    return this.profile.tasksAndSkills || defaultTasksAndSkills;
  }

  get numberOfWorkYears(): number | null {
    return FilterHelpers.getNumberOfYearsFromDate(
      this.profile.experience?.numberOfWorkYearsStartDate ?? null
    );
  }

  get experience(): TalentExperience {
    return this.profile.experience || defaultExperience;
  }

  get educations(): EducationSaved[] {
    return this.experience.education;
  }

  get employments(): EmploymentSaved[] {
    return this.experience.employments;
  }

  get sortedEmployments(): EmploymentSaved[] {
    return [...this.employments].sort(SortHelper.byLatestEmployment());
  }

  loadedLinkedInCompanies: Record<string, ThingToLoad<linkedInCompany[]>> = {};

  @Mutation
  SET_LOADED_LINKEDIN_COMPANIES(v: {
    text: string;
    value: ThingToLoad<linkedInCompany[]>;
  }) {
    this.loadedLinkedInCompanies = {
      ...this.loadedLinkedInCompanies,
      [v.text]: v.value,
    };
  }

  @Action
  async loadLinkedInCompanies(v: { text: string }) {
    const thingToLoad = TokenHelpers.getThingToLoadOrExit(
      this.loadedLinkedInCompanies[v.text] ?? {
        type: "notFetched",
      },
      false
    );

    if (thingToLoad.type === "exit") {
      return;
    }

    talentProfileStore.SET_LOADED_LINKEDIN_COMPANIES({
      text: v.text,
      value: thingToLoad,
    });

    const token = await TokenHelpers.getToken();

    const resultWithExtendedData: linkedInCompany[] = await lookupDataService.getLinkedInCompaniesBySearchTermAndLogos(
      {
        text: v.text,
        token,
      }
    );

    talentProfileStore.SET_LOADED_LINKEDIN_COMPANIES({
      text: v.text,
      value: { type: "loaded", value: resultWithExtendedData },
    });
  }

  get roleIds(): string[] {
    return [...new Set(this.employments.map(e => e.roleId))];
  }

  get roles(): RoleWithTitles[] {
    const emps = this.sortedEmployments;

    return this.roleIds
      .reduce((acc: EmploymentWithAllEmployments[], id) => {
        const allEmps = emps.filter(e => e.roleId === id);

        return allEmps.length ? [...acc, { ...allEmps[0]!, allEmps }] : acc;
      }, [])
      .sort(SortHelper.byLatestEmployment())
      .map(e => {
        return {
          roleId: e.roleId,
          titles: [...new Set(e.allEmps.map(e => e.title.text))],
          employmentIds: e.allEmps.map(e => e.id),
        };
      });
  }

  get rolesWithTasks() {
    return this.roles.map(r => {
      const tasks = profileLookupsStore.tasks.filter(t =>
        t.roleIds.includes(r.roleId)
      );

      const talentRole = this.skills.roles.find(x => x.roleId === r.roleId);

      return {
        ...r,
        isTouched: tasks.some(taskForRole =>
          ((talentRole && talentRole.taskIds) || []).includes(taskForRole.id)
        ),
        tasks,
        hidden: this.hiddenRoleIds.includes(r.roleId),
      };
    });
  }

  get hiddenRoleIds(): string[] {
    return [];
  }

  get allItemsAreDone(): boolean {
    return this.completionListItems.every(x => x.isDone);
  }

  get completionListItems(): CompletionListItem[] {
    const getListItemByType = (
      type: CompletionListType
    ): CompletionListItem => {
      switch (type) {
        case "EMPLOYMENTS":
          return {
            id: type,
            text: $i18n.t("completionListItemTexts.EMPLOYMENTS").toString(),
            isDone:
              dontNeedEmployments(this.profile) ||
              (!!this.profile.experience &&
                !!this.profile.experience.employments.length),
          };
        case "TASKS": {
          let isDone = dontNeedTasks(this.profile);
          if (!isDone) {
            const taskIds: string[] = (
              (!!this.profile.tasksAndSkills &&
                this.profile.tasksAndSkills.roles) ||
              []
            ).reduce((acc: string[], r) => {
              return [...acc, ...r.taskIds];
            }, []);

            isDone = taskIds.length >= 3;
          }

          return {
            id: type,
            text: $i18n.t("completionListItemTexts.TASKS").toString(),
            isDone,
          };
        }
        case "SKILLS":
          return {
            id: type,
            text: $i18n.t("completionListItemTexts.SKILLS").toString(),
            isDone:
              !!this.profile.tasksAndSkills &&
              !!this.profile.tasksAndSkills.skills.length,
          };
        case "EDUCATION":
          return {
            id: type,
            text: $i18n.t("completionListItemTexts.EDUCATION").toString(),
            isDone:
              !!this.profile.experience &&
              (this.profile.experience.hasNoEducation ||
                !!this.profile.experience.education.length),
          };
        case "LANGUAGES":
          return {
            id: type,
            text: $i18n.t("completionListItemTexts.LANGUAGES").toString(),
            isDone:
              !!this.profile.experience &&
              !!this.profile.experience.languageIds.length,
          };
      }
    };

    const requiredTypes: CompletionListType[] = [
      "EMPLOYMENTS",
      "TASKS",
      "SKILLS",
      "EDUCATION",
      "LANGUAGES",
    ];

    return requiredTypes
      .map(getListItemByType)
      .sort((b, a) => (a.isDone > b.isDone ? -1 : b.isDone > a.isDone ? 1 : 0));
  }

  get askForBranchExperience(): boolean {
    return TalentHelpers.getAskForBranchExperience(
      profileLookupsStore.roles,
      this.employments
    );
  }

  get talentName(): string | null {
    return this.profile.firstName
      ? `${this.profile.firstName} ${this.profile.lastName}`
      : null;
  }
}
